import {
  IControllerFactories,
  IControllerFactoryConfig,
} from '@wix/wixstores-client-core/dist/es/src/viewer-script/createViewerScript';
import {StoresWidgetID} from '@wix/wixstores-client-core/dist/es/src/constants';
import {productPageController} from '@wix/wixstores-client-worker/dist/src/viewer-script/controllers/productPage';
import {CART_ICON_DSN, PRODUCT_PAGE_DSN} from '../constants';
import {withErrorReporting} from '@wix/wixstores-client-core/dist/es/src/viewer-script/errorReporter';
import {cartWidgetController} from './cartIconController';

export const viewerScriptConfig: IControllerFactories = {
  [StoresWidgetID.CART_ICON]: {
    factory: cartWidgetController,
    sentryDSN: CART_ICON_DSN,
  },
  [StoresWidgetID.PRODUCT_PAGE]: {
    factory: ({context: controllerContext, reportError}: IControllerFactoryConfig) =>
      productPageController(undefined, undefined, {
        _instance: controllerContext.siteStore.instance,
        reportError,
        wrapExportsRaven: withErrorReporting(reportError),
      }),
    sentryDSN: PRODUCT_PAGE_DSN,
  },
};
