import _ from 'lodash';
import {cartIconTranslationPath, POPUP_URL, EMPTY_CART_GUID, CART_ICON_APP_NAME} from '../constants';
import {ProductType} from '@wix/wixstores-client-core/dist/es/src/types/product';
import {CartType} from '@wix/wixstores-client-core/dist/es/src/types/cart';
import {IAddToCartOptions, IProductCustomTextAnswer} from '../types/product';
import {CartActions} from '@wix/wixstores-client-core/dist/es/src/cart-actions/cartActions';
import {ICartItem, IDataResponse} from '../types/cart';
import {create} from '@wix/fedops-logger';
import {getTranslations, translate, isWorker} from '@wix/wixstores-client-core/dist/es/src/viewer-script/utils';
import {SiteStore} from '@wix/wixstores-client-core/dist/es/src/viewer-script/site-store/siteStore';
import {PubSubManager} from '@wix/wixstores-client-core/dist/es/src/pub-sub-manager/pubSubManager';
import {newCartToOldCartStructure} from '@wix/wixstores-client-core/dist/es/src/common/cart-services/cart-services';
import {ICartIconControllerConfig, ICtrlProps} from '../types/app-types';
import {getLocaleNamespace} from '@wix/wixstores-multilingual-adapter/dist/src/locale-namespace';
import {IPlatformAPI} from '@wix/native-components-infra/dist/es/src/types/types';
import {Topology, PageMap} from '@wix/wixstores-client-core/dist/src/constants';
import {ICart} from '@wix/wixstores-graphql-schema';
import {StoresWidgetID, APP_DEFINITION_ID} from '@wix/wixstores-client-core/dist/es/src/constants';

export class CartStore {
  private translations = {};
  private cart;
  private readonly cartActions: CartActions;
  private readonly fedopsLogger;
  private cartPromise: Promise<any>;
  private readonly pubSubManager: PubSubManager;
  private readonly isStartReported: boolean = false;

  constructor(
    private readonly siteStore: SiteStore,
    private readonly config: ICartIconControllerConfig,
    private readonly compId: string,
    private readonly updateComponent: Function,
    private readonly reportError: (e) => any,
    platformAPIs: IPlatformAPI
  ) {
    this.pubSubManager = new PubSubManager(this.siteStore.pubSub);
    this.cartActions = new CartActions(this.siteStore, this.pubSubManager, 'wixcode', PageMap.CART);
    if (!this.siteStore.isEditorMode()) {
      this.registerEvents();
      this.registerWixCode();
    } else {
      window.onunload = () => this.pubSubManager.publish('Minicart.isExist', false, true);
    }

    const fedopsAppName = `${CART_ICON_APP_NAME}${isWorker() ? '-ooi' : ''}`;

    if (isWorker()) {
      this.fedopsLogger = create(fedopsAppName, {
        isWidgetLogger: true,
        isServerSide: this.siteStore.isSSR(),
        appId: APP_DEFINITION_ID,
        widgetId: StoresWidgetID.CART_ICON,
      });
      const sessionKey = `${compId}_instance`;

      if (platformAPIs.storage.session.getItem(sessionKey) !== this.siteStore.instance) {
        this.fedopsLogger.appLoadStarted();
        this.isStartReported = true;
        platformAPIs.storage.session.setItem(sessionKey, this.siteStore.instance);
      }
    } else {
      this.fedopsLogger = create(fedopsAppName);
    }
  }

  public registerWixCode(): void {
    this.pubSubManager.subscribe(`Wixcode.AddToCart_${this.compId}`, ({data}) =>
      this.addToCart(data.productId, data.quantity, data.options)
        .then(() => this.pubSubManager.publish(`Wixcode.AddToCart.Added_${this.compId}`, {isResolve: true}))
        .catch(() => this.pubSubManager.publish(`Wixcode.AddToCart.Added_${this.compId}`, {isResolve: false}))
    );
  }

  public updateCart(cart: ICart): void {
    this.cart = cart;
    const count = this.getTotalQuantity(this.cart.items);
    this.updateComponent({
      ...this.getCountRelatedProps(count),
    });
  }

  public registerEvents(): void {
    this.pubSubManager.subscribe('Cart.Cleared', () => {
      this.updateCart({items: []});
    });

    this.pubSubManager.subscribe('Cart.Changed', res => {
      this.updateCart(res.data);
    });

    if (!this.siteStore.isMobile()) {
      setTimeout(this.initPopup, 0);
    }
  }

  public async setInitialState(): Promise<void> {
    return Promise.all([
      this.getData(),
      getTranslations(cartIconTranslationPath(this.siteStore.baseUrls.cartIconBaseUrl, this.siteStore.locale)),
      this.siteStore.getSectionUrl(PageMap.CART),
    ])
      .then(([serverResponse, translationsResponse, cartLink]) => {
        this.cart = serverResponse.cartSummary;
        this.translations = translationsResponse;
        const count = this.getTotalQuantity(this.cart.items);

        const props = {
          ...this.getCountRelatedProps(count),
          cartLink: _.get(cartLink, 'url', ''),
          isInteractive: this.siteStore.isInteractive(),
          isLoaded: true,
          displayText: this.getDisplayText(serverResponse.widgetSettings),
          triggerFocus: false,
          onFocusTriggered: this.onFocusTriggered,
          isNavigate: !this.isOpenPopup(),
          onIconClick: this.onIconClick,
          onAppLoaded: this.onAppLoaded,
          cssBaseUrl: this.siteStore.baseUrls.cartIconBaseUrl,
        } as ICtrlProps;
        this.updateComponent(props);
      })
      .then(() => {
        if (this.siteStore.isSSR()) {
          this.fedopsLogger.appLoaded();
        }
      })
      .catch(this.reportError);
  }

  public onFocusTriggered = () => {
    this.updateComponent({
      triggerFocus: false,
    });
  };

  public onAppLoaded = () => {
    if (!isWorker() || (this.siteStore.isInteractive() && this.isStartReported)) {
      this.fedopsLogger.appLoaded();
    }
  };

  public getDisplayText(widgetSettings: {[key: string]: string}): string {
    const defaultValue = this.translations[`CART_ICON_${this.config.style.styleParams.numbers.cartWidgetIcon}`];
    let widgetSettingsForLocale = {};
    if (
      this.siteStore.getMultiLangFields() &&
      !this.siteStore.getMultiLangFields().isPrimaryLanguage &&
      widgetSettings
    ) {
      widgetSettingsForLocale = widgetSettings[getLocaleNamespace(this.siteStore.getMultiLangFields().lang)];
      return _.get(widgetSettingsForLocale, 'CART_ICON_TEXT', '') || defaultValue;
    }
    return _.get(this.config, 'publicData.APP.CART_ICON_TEXT', '') || defaultValue;
  }

  public async getData(): Promise<IDataResponse> {
    if (this.cartPromise) {
      return this.cartPromise;
    }

    const postData = {
      query: require('!raw-loader!../graphql/getData.graphql'),
      source: 'WixStoresWebClient',
      operationName: 'getCartService',
      variables: {externalId: this.config.externalId || ''},
    };

    this.cartPromise = this.siteStore.httpClient
      .post(this.siteStore.resolveAbsoluteUrl(`/${Topology.READ_WRITE_GRAPHQL_URL}`), postData)
      .then(({data}) => {
        return {
          cartSummary: newCartToOldCartStructure(data.cartService.cart as any),
          widgetSettings: _.get(data, 'appSettings.widgetSettings', {}),
        };
      });

    return this.cartPromise;
  }

  public isOpenPopup(): boolean {
    return (
      (!this.config.style.styleParams.numbers.iconLink || this.config.style.styleParams.numbers.iconLink === 2) &&
      !this.siteStore.isMobile()
    );
  }

  public openPopup = () => {
    let popupUrl = POPUP_URL;

    if (this.config.externalId) {
      popupUrl += `?externalId=${this.config.externalId}`;
    }

    this.siteStore.windowApis.openPersistentPopup(
      // tslint:disable-line no-floating-promises
      popupUrl,
      {
        theme: 'BARE',
        width: '0%',
        height: '100%',
        position: {
          origin: 'FIXED',
          placement: 'TOP_RIGHT',
          x: 0,
          y: 0,
        },
      },
      this.compId
    );
    this.pubSubManager.publish('Minicart.isExist', true, true);
  };

  public onIconClick = () => {
    const cartId = this.cart.cartId === EMPTY_CART_GUID ? '' : this.cart.cartId;
    const partialBi = {
      cartId: cartId || '',
      cartType: this.getCartType(),
      itemsCount: this.getTotalQuantity(this.cart.items),
      viewMode: this.siteStore.viewMode.toLowerCase(),
    };
    if (this.isOpenPopup()) {
      this.pubSubManager.publish('Minicart.Toggle', null, false);
      const eventId = this.pubSubManager.subscribe('Minicart.DidClose', () => {
        this.updateComponent({
          triggerFocus: true,
        });
        this.pubSubManager.unsubscribe('Minicart.DidClose', eventId);
      });
      //tslint:disable-next-line:no-floating-promises
      this.siteStore.biLogger.clickOnCartIconToOpenMiniCartSf({
        ...partialBi,
        isNavigateCart: false,
      });
    } else {
      const origin = 'cart-icon';
      //tslint:disable-next-line:no-floating-promises
      this.siteStore.biLogger.clickToViewCartPageSf({
        ...partialBi,
        origin,
        isNavigateCart: true,
      });
      this.cartActions.navigateToCart(origin);
    }
  };

  public listenLoadedMinicartPopupAndSendCart(): void {
    this.pubSubManager.subscribe(
      'Minicart.LoadedWithoutData',
      () =>
        this.getData().then(cart => {
          this.pubSubManager.publish('Minicart.OnInitialData', cart.cartSummary);
          this.cart = cart.cartSummary;
        }),
      true
    );
  }

  public initPopup = () => {
    this.listenLoadedMinicartPopupAndSendCart();
    this.openPopup();
  };

  public addToCart(productId: string, quantity: number, options: IAddToCartOptions = {}): Promise<boolean> {
    if (!productId) {
      return Promise.reject();
    }
    const customTextFields: IProductCustomTextAnswer[] =
      options.customTextFields &&
      options.customTextFields.map(({title, value}) => ({
        customText: {
          title,
        },
        answer: value,
      }));
    return this.cartActions
      .addToCart(productId, options.choices || {}, quantity === undefined ? 1 : quantity, customTextFields)
      .then(() => true);
  }

  public unSubscribeAll(): void {
    return this.pubSubManager.unsubscribeAll();
  }

  private getCartType() {
    const hasDigital = this.cart.items.some(item => item.productType === ProductType.DIGITAL);
    const hasPhysical = this.cart.items.some(item => !item.productType || item.productType === ProductType.PHYSICAL);
    if (hasDigital && hasPhysical) {
      return CartType.MIXED;
    } else if (hasDigital) {
      return CartType.DIGITAL;
    } else {
      return CartType.PHYSICAL;
    }
  }

  private getTotalQuantity(cartItems: ICartItem[] = []): number {
    return cartItems.reduce((previousValue, currentValue) => {
      return previousValue + (currentValue.quantity || 0);
    }, 0);
  }

  private getCountRelatedProps(count: number) {
    return {
      count,
      ariaLabelLink: translate(_.get(this.translations, 'sr.CART_WIDGET_BUTTON_TEXT', ''), {
        itemsCount: `${count}`,
      }),
    };
  }
}
