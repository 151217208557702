import {IControllerFactoryConfig} from '@wix/wixstores-client-core/dist/es/src/viewer-script/createViewerScript';
import {I$W, IWidgetController} from '@wix/native-components-infra/dist/es/src/types/types';
import {CartStore} from './cartStore';
import {ICartIconControllerConfig} from '../types/app-types';
import {withErrorReporting} from '@wix/wixstores-client-core/dist/es/src/viewer-script/errorReporter';

export function cartWidgetController(controllerFactoryConfig: IControllerFactoryConfig): IWidgetController {
  let cartStore: CartStore;

  const {compId, config, platformAPIs, setProps, context: factoryContext, reportError} = controllerFactoryConfig;

  return {
    pageReady: () => {
      cartStore = new CartStore(factoryContext.siteStore, config, compId, setProps, reportError, platformAPIs);
      return cartStore.setInitialState();
    },
    updateConfig: (_$w: I$W, updatedConfig: ICartIconControllerConfig) => {
      config.style = updatedConfig.style;
      config.publicData = updatedConfig.publicData;
      setProps({displayText: cartStore.getDisplayText({})});
    },
    onBeforeUnLoad: () => {
      cartStore.unSubscribeAll();
    },
    exports: () =>
      withErrorReporting(controllerFactoryConfig.reportError)({
        addToCart(productId: string, quantity: number = 1, options: any): Promise<boolean> {
          //tslint:disable-line
          return cartStore.addToCart(productId, quantity, options);
        },
      }),
  };
}
